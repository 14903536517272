<template>
	<!-- begin::page-container(#회원가입) -->
	<div class="page-container" id="page-policies">
		<div class="page-component">
			<header class="page-header">
				<h2 class="title">2024년 소상공인시장진흥공단 '온라인 시장 진출교육' 수강을 위한 개인정보 수집·이용 및 제3자 동의서</h2>
			</header>
			<div class="page-body">
				<div class="tos-content">
					<p class="pt-2">온라인 시장 진출교육 수강을 위하여 관계 법령에 따라 아래와 같이 개인정보를 수집 및 활용하는 내용을 알려드리니 자세히 읽으신 후 동의 여부를 결정하여 주십시오. 개인정보 수집·이용 및 제3자 제공 동의한 회원은 한국중소벤처기업유통원의 교육 플랫폼을 통해 '온라인 시장 진출교육' 강의를 수강하실 수 있으며, 동의 여부와 관계없이 플랫폼 회원가입 가능합니다.</p>
					<h3 class="title pt-3">온라인 시장 진출교육 수강신청</h3>
					<div class="tos-table" id="tos-table-01">
						<table>
							<tbody>
								<tr>
									<td style="background-color: #ebebeb">
										<p style="text-align: center"><b>교육주관 기관</b></p>
									</td>
									<td style="background-color: #ebebeb">
										<p style="text-align: center"><b>교육명</b></p>
									</td>
									<td style="background-color: #ebebeb">
										<p style="text-align: center"><b>참고</b></p>
									</td>
								</tr>
								<tr>
									<td><p style="text-align: center; margin: 10px">소상공인시장진흥공단</p></td>
									<td><p style="text-align: center; margin: 10px">온라인 시장 진출교육</p></td>
									<td>
										<p style="text-align: center">소진공 교육 신청자에 한하여 개인정보 수집·이용 및 교육 관리를 위해 소상공인시장진흥공단에 제공됩니다.</p>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<h3 class="title">개인정보 수집·이용 내역</h3>
					<p class="pt-2">본 양식은 교육 플랫폼 회원 등록, 교육과정 입과, 교육생 수료 현황 관리를 위한 용도로만 이용되며, 본 교육을 위해 수집한 개인정보는 교육 플랫폼 회원등록 및 온라인 시장 진출교육 종료 이후 모든 정보는 즉시 폐기됩니다.</p>
					<div class="tos-table">
						<table id="tos-table-02">
							<tbody>
								<tr>
									<td style="background-color: #ebebeb">
										<p style="text-align: center"><b>수집·이용 항목</b></p>
									</td>
									<td style="background-color: #ebebeb">
										<p style="text-align: center"><b>수집·이용 목적</b></p>
									</td>
									<td style="background-color: #ebebeb">
										<p style="text-align: center"><b>보유·이용 기간</b></p>
									</td>
								</tr>
								<tr>
									<td><p style="text-align: center">성명, 전화번호, 이메일, 가치삽시다 ID</p></td>
									<td><p style="text-align: center">교육 플랫폼 회원(권한)등록 및 수료 현황 관리 등</p></td>
									<td><p style="text-align: center">온라인 시장 진출교육 종료시까지</p></td>
								</tr>
							</tbody>
						</table>
						<p class="pt-2">
							<strong>
								※위의 개인정보 제공 동의 및 이용을 거부할 권리가 있습니다. <br />
								그러나 개인정보 수집·이용에 거부하는 경우, 소상공인시장진흥공단의 온라인 시장 진출교육을 수강하실 수 없습니다.
							</strong>
						</p>
					</div>
					<h3 class="title">개인정보 제 3자 제공 내역</h3>
					<div class="tos-table" id="tos-table-03">
						<table>
							<tbody>
								<tr>
									<td style="background-color: #ebebeb">
										<p style="text-align: center"><b>제공받는 기관</b></p>
									</td>
									<td style="background-color: #ebebeb">
										<p style="text-align: center"><b>제공 목적</b></p>
									</td>
									<td style="background-color: #ebebeb">
										<p style="text-align: center"><b>제공하는 항목</b></p>
									</td>
									<td style="background-color: #ebebeb">
										<p style="text-align: center"><b>보유 기간</b></p>
									</td>
								</tr>
								<tr>
									<td><p style="text-align: center; margin: 10px">소상공인시장진흥공단</p></td>
									<td><p style="text-align: center; margin: 10px">온라인 시장 진출교육 수료생 및 데이터 관리</p></td>
									<td><p style="text-align: center">성명, 전화번호, 이메일, 수료 현황, 과정 진도율, 수료증 발급 현황 등 교육 데이터</p></td>
									<td><p style="text-align: center; margin: 10px">온라인 시장 진출교육 종료시 까지</p></td>
								</tr>
							</tbody>
						</table>
					</div>
					<p class="pt-2">
						<strong>
							※위 개인정보의 제3자 제공에 동의를 거부할 권리가 있습니다.<br />
							그러나 동의를 거부하는 경우, 소상공인시장진흥공단의 온라인 시장 진출교육을 수강하실 수 없습니다.
						</strong>
					</p>
				</div>
			</div>
		</div>
	</div>
	<!-- end::page-container(#회원가입) -->
</template>
<style>
#page-policies .page-header {
	position: relative;
	padding-top: 50px;
	padding-bottom: 30px;
	border-bottom: 1px solid #ebebeb;
}

#page-policies .page-header .title {
	font-weight: 900;
	font-size: 30px;
	color: #111;
	line-height: 35px;
}

#page-policies .tos-content {
	position: relative;
	padding-top: 10px;
	white-space: pre-line;
}

#page-policies .tos-table {
	overflow-x: auto;
	margin-top: 20px;
	margin-bottom: 30px;
}

#page-policies .tos-table table {
	min-width: 1020px;
	width: 99.9%;
	table-layout: fixed;
	font-size: 15px;
	line-height: 20px;
}

thead,
tbody,
tfoot,
tr,
td,
th {
	border-color: inherit;
	border-style: solid;
	border-width: 0;
}

#page-policies .tos-table tbody td {
	height: 60px;
	padding: 20px;
	border: 1px solid #999;
	vertical-align: top;
}

#page-policies .tos-content p {
	font-size: 15px;
	line-height: 20px;
}

#page-policies .tos-content .title {
	font-size: 20px;
	font-weight: 800;
	line-height: 26px;
}

.pt-2 {
	padding-top: 10px !important;
}

b,
strong {
	font-weight: 800;
}
</style>
<script>
export default {
	name: 'Privacy',
};
</script>
